export function fixFont(font: string): string {
  if (!font) {
    return font;
  }
  const parsedFont = JSON.parse(font);
  if (parsedFont.value) {
    return font;
  } else {
    try {
      const fontValue = parsedFont.cssFontFamily.split(`'`)[1];
      const fixedFont = {
        ...parsedFont,
        value: fontValue,
        family: fontValue,
      };
      return JSON.stringify(fixedFont);
    } catch (err) {
      console.error('Error with fixing a specific font: ', err);
      return font;
    }
  }
}
