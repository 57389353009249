import { getValueOf } from '../style-migration-utils';

export function getNewCalendarPageData(oldCalendarPageProperties) {
  // const headerVisibility = false;
  // const gallerySliderLoopImages = false;
  // const gallerySliderAutoSlide = false;
  // const bodyAlignment = getValueOf(
  //   oldCalendarPageProperties.param_font_offeringPageAlignmentTitle,
  // );
  // const policySectionTitle = getValueOf(
  //   oldCalendarPageProperties.param_font_offeringPageTextCancellationPolicy,
  // );
  // const serviceUnavailableMessageText = getValueOf(
  //   oldCalendarPageProperties.param_font_offeringPageTextServiceUnavailable,
  // );
  // const bookButtonText = getValueOf(
  //   oldCalendarPageProperties.param_font_offeringPageTextButton,
  // );
  // const pendingApprovalButtonText = getValueOf(
  //   oldCalendarPageProperties.param_font_offeringPagePendingTextButton,
  // );
  const newCalendarPagedData: any = {
    // gallerySliderLoopImages,
    // gallerySliderAutoSlide,
    // headerVisibility,
    // bodyAlignment,
    // policySectionTitle,
    // serviceUnavailableMessageText,
    // bookButtonText,
    // pendingApprovalButtonText,
  };

  Object.keys(newCalendarPagedData).forEach(
    (key) =>
      newCalendarPagedData[key] === undefined &&
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newCalendarPagedData[key],
  );
  return newCalendarPagedData;
}
