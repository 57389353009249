import { fixFont } from '../../migration/styles-transpiler.utils';

export function getNewCalendarPageStyles(oldCalendarPageProperties) {
  const newStyles = {
    // param_font_bodyServiceTitleFont: fixFont(
    //   oldServicePageProperties.param_font_offeringPageFontServiceTitle,
    // ),
    // param_color_bodyServiceTitleColor:
    //   oldServicePageProperties.param_color_offeringPageColorServiceTitle,
    // param_font_sidebarServiceTitleFont: fixFont(
    //   oldServicePageProperties.param_font_offeringPageFontServiceTitle,
    // ),
    // param_color_sidebarServiceTitleColor:
    //   oldServicePageProperties.param_color_offeringPageColorServiceTitle,
    // param_font_bodyServiceTaglineFont: fixFont(
    //   oldServicePageProperties.param_font_offeringPageFontServiceShortDescription,
    // ),
    // param_color_bodyServiceTaglineColor:
    //   oldServicePageProperties.param_color_offeringPageColorServiceShortDescription,
    // param_font_sidebarServiceTaglineFont: fixFont(
    //   oldServicePageProperties.param_font_offeringPageFontServiceShortDescription,
    // ),
    // param_color_sidebarServiceTaglineColor:
    //   oldServicePageProperties.param_color_offeringPageColorServiceShortDescription,
    // param_font_bodySectionTitleFont: fixFont(
    //   oldServicePageProperties.param_font_offeringPageFontServiceSmallTitles,
    // ),
    // param_color_bodySectionTitleColor:
    //   oldServicePageProperties.param_color_offeringPageColorServiceSmallTitles,
    // param_font_sidebarTitleFont: fixFont(
    //   oldServicePageProperties.param_font_offeringPageFontServiceSmallTitles,
    // ),
    // param_color_sidebarTitleColor:
    //   oldServicePageProperties.param_color_offeringPageColorServiceSmallTitles,
    // param_font_bodyTextFont: fixFont(
    //   oldServicePageProperties.param_font_fontBodyText,
    // ),
    // param_color_bodyTextColor:
    //   oldServicePageProperties.param_color_colorBodyText,
    // param_font_sidebarTextFont: fixFont(
    //   oldServicePageProperties.param_font_fontBodyText,
    // ),
    // param_color_sidebarTextColor:
    //   oldServicePageProperties.param_color_colorBodyText,
    // param_font_detailsBoxFont: fixFont(
    //   oldServicePageProperties.param_font_fontBodyText,
    // ),
    // param_color_detailsBoxTextColor:
    //   oldServicePageProperties.param_color_colorBodyText,
    // param_font_scheduleDatesFont: fixFont(
    //   oldServicePageProperties.param_font_fontBodyText,
    // ),
    // param_color_scheduleDatesColor:
    //   oldServicePageProperties.param_color_colorBodyText,
    // param_font_scheduleHoursFont: fixFont(
    //   oldServicePageProperties.param_font_fontBodyText,
    // ),
    // param_color_scheduleHoursColor:
    //   oldServicePageProperties.param_color_colorBodyText,
    // param_font_scheduleDetailsFont: fixFont(
    //   oldServicePageProperties.param_font_fontBodyText,
    // ),
    // param_color_scheduleDetailsColor:
    //   oldServicePageProperties.param_color_colorBodyText,
    // param_font_scheduleLinkFont: fixFont(
    //   oldServicePageProperties.param_font_fontBodyText,
    // ),
    // param_color_scheduleLinkColor:
    //   oldServicePageProperties.param_color_colorBodyText,
    // param_color_bodyBackgroundColor:
    //   oldServicePageProperties.param_color_colorPageBackground,
    // 'alpha-param_color_bodyBackgroundColor':
    //   oldServicePageProperties['alpha-param_color_colorPageBackground'],
    // param_color_sidebarBackgroundColor:
    //   oldServicePageProperties.param_color_colorPageBackground,
    // 'alpha-param_color_sidebarBackgroundColor':
    //   oldServicePageProperties['alpha-param_color_colorPageBackground'],
    // param_font_bodyButtonTextFont: fixFont(
    //   oldServicePageProperties.param_font_fontButtonText,
    // ),
    // param_color_bodyButtonTextColor:
    //   oldServicePageProperties.param_color_colorPrimaryButtonText,
    // param_font_sidebarButtonTextFont: fixFont(
    //   oldServicePageProperties.param_font_fontButtonText,
    // ),
    // param_color_sidebarButtonTextColor:
    //   oldServicePageProperties.param_color_colorPrimaryButtonText,
    // param_color_bodyButtonHoverTextColor:
    //   oldServicePageProperties.param_color_colorPrimaryButtonText,
    // param_color_sidebarButtonHoverTextColor:
    //   oldServicePageProperties.param_color_colorPrimaryButtonText,
    // param_color_bodyButtonBackgroundColor:
    //   oldServicePageProperties.param_color_colorButton,
    // param_color_bodyButtonBorderColor:
    //   oldServicePageProperties.param_color_colorButton,
    // param_color_sidebarButtonBackgroundColor:
    //   oldServicePageProperties.param_color_colorButton,
    // param_color_sidebarButtonBorderColor:
    //   oldServicePageProperties.param_color_colorButton,
    // param_color_bodyButtonHoverBackgroundColor:
    //   oldServicePageProperties.param_color_colorButton,
    // param_color_bodyButtonHoverBorderColor:
    //   oldServicePageProperties.param_color_colorButton,
    // param_color_sidebarButtonHoverBackgroundColor:
    //   oldServicePageProperties.param_color_colorButton,
    // param_color_sidebarButtonHoverBorderColor:
    //   oldServicePageProperties.param_color_colorButton,
    // param_color_sidebarButtonHoverBorderColor:
    //   oldCalendarPageProperties.param_color_colorButton,
  };
  Object.keys(newStyles).forEach(
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    (key) => newStyles[key] === undefined && delete newStyles[key],
  );
  return newStyles;
}
